import { Box, Grid, Typography } from '@mui/material';
import imgDesk from '../../img/team/desk.jpg';
import { value_innovation, value_efficiency, value_affordability } from '../../text';

const Values = () => {
  return (
    <>
      {values()}
      {photo()}
    </>
  )
};

export default Values;

export function photo() {
  return (
    <Grid item xs={12} md={6}>
      <Box component="img" src={imgDesk} alt="Our values image" sx={{ width: '100%', borderRadius: '10px' }}/>
    </Grid>
  );
}

export function values() {
  return (
    <Grid item xs={12} md={6}>
      <Box
        sx={{
          backgroundColor: 'rgba(31, 31, 31, 0.8)',
          color: 'white',
          padding: '30px',
          borderRadius: '10px',
        }}>
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontFamily: 'Arial',
            fontSize: '40px',
            fontWeight: 'bold',
            marginBottom: '20px',
          }}>
          Our Values
        </Typography>

        {/* Updated to use a list for better formatting */}
        <Typography sx={{ textAlign: 'justify', fontSize: '18px' }}>
          <ul style={{ paddingLeft: '20px' }}>
            <li style={{ marginBottom: '15px' }}>
              <Typography component="span" sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                Innovation:
              </Typography>
              <Typography component="span" sx={{ marginLeft: '10px' }}>
                {value_innovation}
              </Typography>
            </li>
            <li style={{ marginBottom: '15px' }}>
              <Typography component="span" sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                Efficiency:
              </Typography>
              <Typography component="span" sx={{ marginLeft: '10px' }}>
                {value_efficiency}
              </Typography>
            </li>
            <li style={{ marginBottom: '15px' }}>
              <Typography component="span" sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                Affordability:
              </Typography>
              <Typography component="span" sx={{ marginLeft: '10px' }}>
                {value_affordability}
              </Typography>
            </li>
          </ul>
        </Typography>
      </Box>
    </Grid>
  );
}