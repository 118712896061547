import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { gradientBackground } from '../../backgroundStyles';
import missionSimulatorImage from '../../img/white_dash.png';
import codeGeneratorImage from '../../img/code_generation.png';

interface DesignerProps {
  handlePageChange: (page: string) => void;
  isMobile: boolean;
}

function GeneratorImg() {
  return(
    <Box
      sx={{
        width: '100%',
        maxWidth: '800px', // Adjust to your preferred width
        height: 'auto', // Allow the height to adjust naturally
        overflow: 'hidden', // Prevent image overflow
        borderRadius: '10px', // Optional: round corners
        marginTop: '20px',
      }}>
      
      <img
        src={codeGeneratorImage}
        alt="Mission Simulator"
        style={{
          display: 'block', // Prevents inline element spacing
          maxWidth: '100%',
          height: 'auto', // Maintain aspect ratio
          objectFit: 'contain', // Ensure the image fits within the container
        }}/>
    </Box>
  );
}

export function CodeGenerator({ handlePageChange }: { handlePageChange: (page: string) => void }) {
  return(
    <Box
      sx={{
        flex: 1,
        color: '#eee',
        border: '1px solid rgba(255, 255, 255, 0.9)',
        backgroundColor: 'transparent',
        padding: '5%',
        margin: '5%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '66vh', // Ensure this is consistent with your layout
        width: '80%',
        overflow: 'hidden', // Prevent content from spilling out
      }}>
      
      <Typography variant="h2" sx={{ fontWeight: 'bold', marginBottom: '10px', textAlign: 'center' }}>
        Code Generator
      </Typography>
      
      <Typography variant="h4" sx={{ marginBottom: '20px', textAlign: 'center', fontSize: '1rem' }}>
        Programming can be convenient
      </Typography>

      <Box sx={{ display: 'flex', gap: '40px' }}>
        <Button variant="outlined" onClick={() => handlePageChange("Solutions")} sx={buttonStyle}>
          Learn more
        </Button>
        {/*<Button variant="outlined" sx={buttonStyle}>
          Buy
        </Button>*/}
      </Box>

      <GeneratorImg/>

    </Box>
  );
}

function MissionImage() {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '800px', // Adjust to your preferred width
        height: 'auto', // Allow the height to adjust naturally
        overflow: 'hidden', // Prevent image overflow
        borderRadius: '10px', // Optional: round corners
        marginTop: '20px',
      }}>
    
      <img
        src={missionSimulatorImage}
        alt="Mission Simulator"
        style={{
          display: 'block', // Prevents inline element spacing
          maxWidth: '100%',
          height: 'auto', // Maintain aspect ratio
          objectFit: 'contain', // Ensure the image fits within the container
        }}/>
    </Box>
  );
}

export function MissionSimulator({ handlePageChange }: { handlePageChange: (page: string) => void }){
  return(
    <Box
      sx={{
        flex: 1,
        color: '#eee',
        border: '1px solid rgba(255, 255, 255, 0.9)',
        backgroundColor: 'transparent',
        padding: '5%',
        margin: '5%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '66vh', // Ensure this is consistent with your layout
        width: '80%',
        overflow: 'hidden', // Prevent content from spilling out
      }}>
    
      <Typography variant="h2" sx={{ fontWeight: 'bold', marginBottom: '10px', textAlign: 'center' }}>
        Mission Simulator
      </Typography>
    
      <Typography variant="h4" sx={{ marginBottom: '20px', textAlign: 'center', fontSize: '1rem' }}>
        Breaking the boundaries of thinking
      </Typography>

      <Box sx={{ display: 'flex', gap: '40px' }}>
        <Button variant="outlined" onClick={() => handlePageChange("Solutions")} sx={buttonStyle}>
          Learn more
        </Button>
        {/*<Button variant="outlined" sx={buttonStyle}>
          Try for free
        </Button>*/}
      </Box>

      <MissionImage/>

    </Box>
  );
}

export function DesktopView(props: DesignerProps) {
  return (
    <Box
      sx={{
        ...gradientBackground, 
        height: "100vh",
        paddingX: { xs: "5%", sm: "10%" },
        display: "flex",
        gap: "50px",
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: "center",             
        backgroundSize: "cover",
        backgroundPosition: "center", 
        position: "relative",       
        justifyContent: { xs: "center", sm: "flex-end" },
        textAlign: { xs: "center", sm: "left" },
        scrollSnapAlign: "start", // Snap each section at the start
      }}>
      
      <MissionSimulator handlePageChange={props.handlePageChange}/>

      <CodeGenerator handlePageChange={props.handlePageChange}/>

    </Box>
  );
}

// TODO: Koldu
export function MobileView(props: DesignerProps){
  return (
    <>
     <Box
              sx={{
                ...gradientBackground, 
                flex: 1,
                color: '#fff',
                backgroundColor: 'transparent',
                //paddingX:  "5%",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100vh', // Ensure this is consistent with your layout
                width: '100%',
                overflow: 'hidden', // Prevent content from spilling out
                scrollSnapAlign: "start", 
              }}
            >
            <Box
              sx={{
                width: '100%',
                height: 'auto', // Allow the height to adjust naturally
                overflow: 'hidden', 
                marginTop: '20px',
              }}
            >
              {/* Product Image */}
              <MissionSimulator handlePageChange={props.handlePageChange}/>
            </Box>
          </Box>  



          <Box
              sx={{
                ...gradientBackground, 
                flex: 1,
                color: '#fff',
                backgroundColor: 'transparent',
                //paddingX:  "5%",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100vh', // Ensure this is consistent with your layout
                width: '100%',
                overflow: 'hidden', // Prevent content from spilling out
                scrollSnapAlign: "start", 
              }}
            >
            <Box
              sx={{
                width: '100%',
                maxWidth: '800px', // Adjust to your preferred width
                height: 'auto', // Allow the height to adjust naturally
                overflow: 'hidden', // Prevent image overflow
                borderRadius: '10px', // Optional: round corners
                marginTop: '20px',
              }}
            >
              {/* Product Image */}
              <CodeGenerator handlePageChange={props.handlePageChange}/>
            </Box>
          </Box>  
    </>
  );
}


export default function PayloadDesigner(props: DesignerProps) {
  if (props.isMobile) {
    return (
      <MobileView handlePageChange={props.handlePageChange} isMobile={props.isMobile}/>
    );
  } else {
    return (
      <DesktopView handlePageChange={props.handlePageChange} isMobile={props.isMobile}/>
    );
  }
};

const buttonStyle = {
  color: '#fff',
  borderColor: '#fff',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderColor: '#fff',
  },
};