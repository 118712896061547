import { Box, Typography, Button } from '@mui/material';
import background from '../../img/background.jpg';
import { acisw_desc } from "../../text";

interface AciSoftwareProps {
  handlePageChange: (page: string) => void;
}

export default function AciSoftware({ handlePageChange }: AciSoftwareProps) {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '80px 20px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        color: 'white',
      }}>
      <Button
        variant="outlined"
        size="medium"
        sx={{
          position: 'absolute',
          top: '20px',
          left: '20px',
          color: 'white',
          borderColor: 'white',
        }}
        onClick={() => handlePageChange('Solutions')}>
        Back to Projects
      </Button>
      <Box>
        <Typography
          variant="h2"
          sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
          AciSoftware
        </Typography>
        <Typography
          variant="h4"
          sx={{ marginBottom: '40px' }}>
          Simplifying Payload Development for Space Missions
        </Typography>
        <Typography
          sx={{
            maxWidth: '800px',
            fontSize: '18px',
            lineHeight: '1.8',
            marginBottom: '40px',
          }}>
          {/*{acisw_desc}*/}
        </Typography>
      </Box>
      <Button
        variant="contained"
        size="large"
        sx={{
          backgroundColor: '#888',
          color: '#fff',
          fontSize: '18px',
          padding: '12px 24px',
          marginBottom: '40px',
          marginTop: '20px',
        }}>
        Coming Soon
      </Button>
    </Box>
  );
}
