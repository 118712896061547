import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React from "react";
import { Box, Typography, Avatar, IconButton } from "@mui/material";
import { LinkedIn, Email } from "@mui/icons-material";

interface TeamMemberProps {
  name: string;
  title: string;
  description: string;
  image: string;
  linkedin: string;
}

const TeamMember: React.FC<TeamMemberProps> = ({ name, title, description, image, linkedin }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: { xs: "column", md: "row" },
        backgroundColor: "#141414",
        padding: { xs: "20px", md: "40px" },
        borderRadius: "15px",
        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.5)",
        maxWidth: "900px",
        margin: "0 auto",
      }}
    >
      {/* Rounded Rectangle Avatar */}
      <Avatar
        src={image}
        alt={name}
        sx={{
          width: 280,
          height: 450,
          borderRadius: "100px",
          marginRight: { md: "40px" },
          marginBottom: { xs: "20px", md: "0" },
          justifyContent: "center",
        }}
      />

      {/* Text Section */}
      <Box sx={{ textAlign: { xs: "center", md: "left" }, flex: 1 }}>
        <Typography variant="h5" sx={{ color: "#fff", fontWeight: "bold" }}>
          {name}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "#aaa", marginBottom: "20px" }}>
          {title}
        </Typography>
        <Typography sx={{ color: "#ccc", marginBottom: "20px", textAlign: "justify" }}>
          {description}
        </Typography>

        {/* Social Media Icons */}
        <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-start" }, gap: 2 }}>
          <IconButton href={linkedin} color="primary">
            <LinkedIn sx={{ color: "#fff" }} />
          </IconButton>
          <IconButton href={`mailto:info@acis.space`} color="primary">
            <Email sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default TeamMember;
