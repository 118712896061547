import { theme } from './theme';
import NavigationBar from './navigation/NavigationBar';
import Footer from "./navigation/Footer";
import { ThemeProvider } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Home from './pages/home/Home';
//import Projects from './pages/projects/Projects';
import Team from './pages/team/Team';
//import Contact from './pages/contact/Contact';
import UnderDevelopment from './pages/UnderDev';
import AciSoftware from './pages/projects/AciSoftware';
import PayloadPlatform from './pages/projects/PayloadPlatform';
//import logo from './img/website_bg.png';
import './fonts.css';

export default function App() {
  const [currentPage, setCurrentPage] = useState('Home');
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handlePageChange = (page: string) => {
    setCurrentPage(page);
  };

  // Scroll to the top whenever the page changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const pages: { [key: string]: JSX.Element } = {
    //'Solutions': <Projects handlePageChange={handlePageChange} />,
    'Solutions': <UnderDevelopment />,
    'About Us': <Team isMobile={isMobile}/>,
    //'Contact': <Contact />,
    'AciSoftware': <AciSoftware handlePageChange={handlePageChange} />,
    'PayloadPlatform': <PayloadPlatform handlePageChange={handlePageChange} />,
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          //backgroundImage: `url(${logo})`,
          //zIndex: 1,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
          overflowY: 'auto',
          scrollSnapType: 'y mandatory',
          scrollBehavior: 'smooth',
        }}>

        <NavigationBar handlePageChange={handlePageChange} currentPage={currentPage} isMobile={isMobile}/>
        <Box sx={{ flex: 1 }}>
          {currentPage === 'Home' ? <Home handlePageChange={handlePageChange} isMobile={isMobile} /> : pages[currentPage]}
        </Box>
        
        <Footer handlePageChange={handlePageChange} currentPage={currentPage} />
      </Box>
    </ThemeProvider>
  );
}
