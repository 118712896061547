import { Box, Grid, Typography } from '@mui/material';
import imgSat from '../../img/team/sat.jpg';
import { what_we_do } from '../../text';

const WhatWeDo = ({isMobile}: {isMobile: boolean}) => {
  if (isMobile) {
    return (
      <>
        {what()}
        {photo()}
      </>
    )
  } else {
    return (
      <>
        {photo()}
        {what()}
      </>
    )
  }
};

export default WhatWeDo;

export function photo() {
  return (
    <Grid item xs={12} md={6}>
      <Box component="img" src={imgSat} alt="What we do image" sx={{ width: '100%', borderRadius: '10px', marginTop: '30px' }} />
    </Grid>
  );
}

export function what() {
  return (
    <Grid item xs={12} md={6}>
    <Box
      sx={{
        backgroundColor: 'rgba(31, 31, 31, 0.8)',
        color: 'white',
        padding: '30px',
        borderRadius: '10px',
        marginTop: '30px',
      }}>
      <Typography variant="h3" gutterBottom sx={{ fontFamily: 'Arial', fontSize: '40px', fontWeight: 'bold' }}>
        What we do
      </Typography>
      <Typography sx={{ textAlign: 'justify' }}>
        {what_we_do}
      </Typography>
    </Box>
  </Grid>
  );
}