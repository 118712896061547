import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import boardImage from '../../img/board.png';
import bgImage from '../../img/board_background.jpg';
import { gradientBackground } from '../../backgroundStyles';

interface BoardProps {
  handlePageChange: (page: string) => void;
  isMobile: boolean;
}

export function Title() {
  return (
    <Typography
      variant="h1" 
      sx={{ 
        fontWeight: "bold", 
        marginTop: { xs: "20%", sm: "5%", md: "5%" },
        marginBottom: { xs: "0%", sm: "2.5rem", md: "3rem" },
        
        fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" }, 
        }}>              
      Payload Platform
    </Typography>
  )
}

export function Subtitle() {
  return (
    <Typography
      variant="h3" 
      sx={{ 
        fontWeight: "bold", 
        marginBottom: "5%",
        fontSize: { xs: "1.5", sm: "2rem", md: "2.5rem" }, 
      }}>              
      Realize your space innovation
    </Typography>
  )
}

export function IntroDescription() {
  return (
    <Typography
      variant="body1" 
      sx={{ 
        fontWeight: "bold", 
        marginBottom: "5%",
        fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" }, 
      }}>              
      Introducing our next generational product
  </Typography>
  )
}

export function ProductImg() {
  return(
    <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={boardImage}
          alt="Product board"
          style={{ width: "100%", maxWidth: "1000px", objectFit: "contain" }}
        />
      </Box>
  )
}

export function DesktopView({ handlePageChange }: { handlePageChange: (page: string) => void }) {
  return (
    <Box
      sx={{
        ...gradientBackground, 
        height: "100vh",
        paddingX: "10%" ,
        display: "flex",
        alignItems: "center",             
        backgroundSize: "cover",
        backgroundPosition: "center", 
        position: "relative",       
        justifyContent: "flex-end" ,
        textAlign:  "left" ,
        scrollSnapAlign: "start",
      }}>

      {/* Text and Button */}
      <Box sx={{ 
        flex: 1, 
        color: "#fff", 
        maxWidth: "500px", 
        textAlign: "left" 
        }}>

        {Title()}
        {Subtitle()}
        {IntroDescription()}

        <Button
          variant="outlined"
          onClick={() => handlePageChange("Solutions")}
          sx={{
            color: "#fff",
            borderColor: "#fff",
            padding: "10px 20px",
            textTransform: "none",
            "&:hover": {
              borderColor: "#fff",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          }}>
          Learn More
        </Button>
      </Box>

      {/* Product Image */}
      {ProductImg()}
  </Box>
  );
}

export function MobileView({ handlePageChange }: { handlePageChange: (page: string) => void }) {
  return (
    <>
      {/* TODO: Mobile View HERE */}
        <Box
          sx={{
            ...gradientBackground, 
            flex: 1,
            color: '#fff',
            backgroundColor: 'transparent',
            //paddingX:  "5%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100vh', // Ensure this is consistent with your layout
            width: '100%',
            overflow: 'hidden', // Prevent content from spilling out
            scrollSnapAlign: "start", 
          }}
        >
          {Title()} 


      {/* Image container */}
        <Box
          sx={{
            width: '100%',
            maxWidth: '800px', // Adjust to your preferred width
            height: 'auto', // Allow the height to adjust naturally
            overflow: 'hidden', // Prevent image overflow
            borderRadius: '10px', // Optional: round corners
            marginTop: '20px',
          }}
        >
          {/* Product Image */}
          {ProductImg()}
        </Box>
        {Subtitle()}
        {IntroDescription()}
        
    <Box sx={{ display: 'flex', gap: '40px' }}>
      <Button
              variant="outlined"
              onClick={() => handlePageChange("Solutions")}
              sx={{
                color: "#fff",
                borderColor: "#fff",
                padding: "10px 20px",
                textTransform: "none",
                "&:hover": {
                  borderColor: "#fff",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}>
              Learn More
            </Button>
      </Box>
      </Box>      
      </>
    );
}

export default function Board(props: BoardProps) {
  console.log("isMobile");
  console.log(props.isMobile);
  if (props.isMobile) {
    return(
      <MobileView handlePageChange={props.handlePageChange}/>
    );
  } else {
    return (
      <DesktopView handlePageChange={props.handlePageChange}/>
    );
  }
};