import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import logo from "../img/logo/navicon.svg";
import { useTheme } from '@mui/material/styles';
import { IconButton, MenuItem, Menu } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const pages: string[] = ['Solutions', 'About Us', /*'Contact'*/];

export default function NavigationBar({ handlePageChange, currentPage, isMobile }: { handlePageChange: (page: string) => void; currentPage: string; isMobile: boolean }) {
  const theme = useTheme();
  const backgroundColor = currentPage === 'Home' ? 'transparent' : 'black';
  
  // State to control the mobile menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <AppBar 
        position="sticky"
        sx={{
          backgroundColor: backgroundColor,
          boxShadow: 'none',
          transition: 'background-color 0.3s ease',
          top: 0,
          zIndex: 10,
          scrollSnapAlign: currentPage === 'Home' ? 'start' : 'none',
        }}>
        <Toolbar disableGutters={false}>
          {/* Logo */}
          <Button onClick={() => handlePageChange('Home')} sx={{ p: 0 }}>
          <img src={logo} alt="AciSpace" style={{ height: '50px', marginRight: '20px' }} />
          </Button>

          {/* Mobile Hamburger Menu */}
          {isMobile ? mobileView(handlePageChange, handleOpenMenu, anchorEl, handleCloseMenu) : desktopView(handlePageChange)}
        </Toolbar>

        {/* Separator Line here */}

      </AppBar>
    </Box>
  );
}

export function mobileView(
  handlePageChange: (page: string) => any, 
  handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void, 
  anchorEl: HTMLElement | null, 
  handleCloseMenu: () => any) {
  return (
    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
      <IconButton
        size="large"
        edge="end"
        color="inherit"
        onClick={handleOpenMenu}>
        <MenuIcon />
      </IconButton>
      
      {/* Dropdown menu for mobile */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{ mt: '45px' }}>
        
        {pages.map((page) => (
          <MenuItem key={page} onClick={() => { handlePageChange(page); handleCloseMenu(); }}>
            {page}
          </MenuItem>
        ))}
        
        <MenuItem onClick={handleCloseMenu}>
          <Button variant="contained" color="info" sx={{ width: '100%' }}>
            Coming Soon
          </Button>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export function desktopView(handlePageChange: (page: string) => any) {
  return (
    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
    {pages.map((page) => (
      <Button
        key={page}
        onClick={() => handlePageChange(page)}
        sx={{ color: 'white', display: 'block', fontWeight: 'bold', textTransform: 'none' }}>
        {page}
      </Button>
    ))}

    {/* "Subscribe" and "Review" Buttons */}
    <Button variant="contained" color="info" sx={{ ml: 1 }}>
      Coming Soon
    </Button>
  </Box>
  )
}