import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#000f2c',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#c2d8ff',
      contrastText: '#ffffff',
    },
    background: {
      default: '#000611',
    },
  },
  // Add more customization here
});
/*
declare module "@mui/material/styles" {
  interface Palette {
    pending: Palette["primary"];
  }
  interface PaletteOptions {
    pending?: PaletteOptions["primary"];
  }
}
*/