import { Box, Grid, Typography } from '@mui/material';
import imgTeam from '../../img/team/team_who.jpg';
import { who_we_are } from '../../text';

const WhoWeAre = () => {
  return (
    <>
      {who()}
      {photo()}
    </>
  );
};

export default WhoWeAre;

export function photo() {
  return (
    <Grid item xs={12} md={6}>
      <Box component="img" src={imgTeam} alt="Who we are image" sx={{ width: '100%', borderRadius: '10px' }} />
    </Grid>
  );
}

export function who() {
  return (
    <Grid item xs={12} md={6}>
      <Box
        sx={{
          backgroundColor: 'rgba(31, 31, 31, 0.8)',
          color: 'white',
          padding: '30px',
          borderRadius: '10px',
        }}>
        <Typography variant="h3" gutterBottom sx={{ fontFamily: 'Arial', fontSize: '50px', fontWeight: 'bold' }}>
          Who we are
        </Typography>
        <Typography sx={{ textAlign: 'justify' }}>
          {who_we_are}
        </Typography>
      </Box>
    </Grid>
  );
}