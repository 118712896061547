import React from "react";
import { Box, Typography, Button } from "@mui/material";
import teamImage from "../../img/team_bg.jpg";

interface MeetTheTeamProps {
  handlePageChange: (page: string) => void;
}

const MeetTheTeam: React.FC<MeetTheTeamProps> = ({ handlePageChange }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${teamImage})`,
        height: "100vh",
        paddingX: { xs: "5%", sm: "10%" },
        display: "flex",
        alignItems: "center",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        justifyContent: { xs: "center", sm: "flex-end" },
        textAlign: { xs: "center", sm: "left" },
        scrollSnapAlign: "start", // Snap each section at the start
      }}
    >
      <Box
        sx={{
          //textAlign: "center",
          maxWidth: { xs: "90%", sm: "60%", md: "40%" },
          color: "white",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: "bold",
            marginBottom: "5%",
            fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" }, 
          }}
        >
          Check out the minds behind
          <br /> {/* Force line break */}
          the solutions
        </Typography>
        <Button
          variant="outlined"
          onClick={() => handlePageChange("About Us")}
          sx={{
            color: "#fff",
            borderColor: "#fff",
            padding: "10px 20px",
            textTransform: "none",
            "&:hover": {
              borderColor: "#fff",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          }}
        >
          Meet the Team
        </Button>
      </Box>
    </Box>
  );
};

export default MeetTheTeam;
