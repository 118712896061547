import { Box, Grid, Typography } from '@mui/material';
import imgRocket from '../../img/team/rocket.jpg';
import { our_vision } from '../../text';

const Vision = ({isMobile}: { isMobile: boolean}) => {
  if (isMobile) {
    return (
      <>
        {photo()}
        {vision()}
      </>
    )
  } else {
    return (
      <>
        {vision()}
        {photo()}
      </>
    )
  }
};

export default Vision;

export function photo() {
  return(
    <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
      <Box component="img" src={imgRocket} alt="Our vision image" sx={{ width: '100%', borderRadius: '10px' }} />
    </Grid>
  )
}

export function vision() {
  return (
  <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
    <Box
      sx={{
        backgroundColor: 'rgba(31, 31, 31, 0.8)',
        color: 'white',
        padding: '30px',
        borderRadius: '10px',
      }}>
      <Typography variant="h3" gutterBottom sx={{ fontFamily: 'Arial', fontSize: '40px', fontWeight: 'bold' }}>
        Our vision
      </Typography>
      <Typography sx={{ textAlign: 'justify' }}>
        {our_vision}
      </Typography>
    </Box>
  </Grid>
  );
}