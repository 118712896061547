import { Container, Box, Grid } from '@mui/material';
import background from '../../img/background.jpg';
import WhoWeAre from './who';
import WhatWeDo from './what';
import Values from './values';
import Vision from './vision';
import MeetCarousel from './Meet';

export default function Team({isMobile}: { isMobile: boolean}) {
  return (
    <Box
    sx={{
      width: '100%',
      minHeight: '100vh',
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      padding: '50px 0',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Container>
        <Grid container spacing={5}>
          <WhoWeAre />
          <WhatWeDo isMobile={isMobile}/>
          <Values />
          <Vision isMobile={isMobile}/>
        </Grid>
        <Box sx={{ marginTop: '50px', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <MeetCarousel />
        </Box>
      </Container>
    </Box>

  );
}
