import React from "react";
import Slider from "react-slick";
import TeamMember from "./member";
import { Box } from "@mui/material";

import hanga from "../../img/team/founders/hanga.jpg";
import boldi from "../../img/team/founders/boldi.jpg";
import marci from "../../img/team/founders/marci.jpg";
import joseph from "../../img/team/founders/joseph.jpg";
import thomas from "../../img/team/founders/thomas.jpg";
import eric from "../../img/team/founders/eric.jpg";
import en from "../../img/team/founders/en.jpg";

import { hanga_intro, joseph_intro, thomas_intro, boldi_intro, maroc_intro, eric_intro, en_intro } from "../../text";

const teamMembers = [
  {
    name: "Hanga Katreiner",
    title: "Space Engineer MSc.",
    description: hanga_intro,
    image: hanga,
    linkedin: "https://www.linkedin.com/in/hanga-katreiner/"
  },
  {
    name: "Boldizsar Markotics",
    title: "Space Engineer MSc.",
    description: boldi_intro,
    image: boldi,
    linkedin: "https://www.linkedin.com/in/boldizsar-markotics/"
  },
  {
    name: "Marcell Kovacs",
    title: "Electrical Engineer BSc.",
    description: maroc_intro,
    image: marci,
    linkedin: ""
  },
  {
    name: "Tzu-Chiao Lin",
    title: "Space Engineer MSc.",
    description: joseph_intro,
    image: joseph,
    linkedin: "https://www.linkedin.com/in/tzu-chiao0122/"
  },
  {
    name: "Ngai Nam Chan",
    title: "Aerospace Engineer MSc.",
    description: thomas_intro,
    image: thomas,
    linkedin: "https://www.linkedin.com/in/thomas-chan-195932161/"
  },
  {
    name: "En Shih",
    title: "Communication Engineer MSc.",
    description: en_intro,
    image: en,
    linkedin: ""
  },
  {
    name: "Eric Chu",
    title: "Electrical Engineer MSc.",
    description: eric_intro,
    image: eric,
    linkedin: ""
  },
];

const MeetCarousel: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '1000px',
        margin: '0 auto',
        padding: '40px 0',
      }}
    >
      <Slider {...settings}>
        {teamMembers.map((member, index) => (
          <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
            <TeamMember
              name={member.name}
              title={member.title}
              description={member.description}
              image={member.image}
              linkedin={member.linkedin}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default MeetCarousel;
