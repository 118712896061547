import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Banner_img from '../img/banner_wbg.png';
import Banner_bg from '../img/website_bg.png';

const UnderDevelopment: React.FC = () => {
  return (
<>
    <Box
    sx={{
      backgroundImage: `url(${Banner_img})`,
      height: "100vh",
      paddingX: { xs: "5%", sm: "10%" },
      display: "flex",
      alignItems: "center",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
      justifyContent: { xs: "center", sm: "flex-end" },
      textAlign: { xs: "left", sm: "left" },
      //scrollSnapAlign: "start",
    }}>

    {/* Fixed background Box */}
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        backgroundImage: `url(${Banner_bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        zIndex: -1,
      }}
    />

    <Box
      sx={{
        color: "white",
        maxWidth: { xs: "100%", sm: "100%", md: "100%" },
      }}>

      <Typography variant="h1" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
        Page Under Construction
      </Typography>
      <Typography variant={"h3"} sx={{  marginBottom: 3 }}>
        We're reaching for the stars, but this page isn't quite ready yet! Thank you for your patience as we continue building something amazing.
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 3}}>
        We’re thrilled about your interest in our space startup and appreciate your patience as we continue to build our website. While this page isn’t ready yet, we’re working hard behind the scenes to bring you the best experience!
        In the meantime, if you have any questions or need assistance  feel free to get in touch with us at info@acis.space
        <br />
        Innovation takes time, and every mission starts with a solid foundation. Thank you for being part of our journey.
      </Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button variant="contained" href="/" sx={{ backgroundColor: '#0077b6' }}>
          Return to Home
        </Button>
      </Box>
    </Box>
  </Box>
  </>
  );
};

export default UnderDevelopment;
