import React from 'react';
import { Box, Container, Grid, Typography, Link, TextField, Button } from '@mui/material';
import { LinkedIn, Email } from '@mui/icons-material';
import logo from '../img/logo/navicon.svg';


interface FooterProps {
  handlePageChange: (page: string) => void;
  currentPage: string;
}

const Footer: React.FC<FooterProps> = ({ handlePageChange, currentPage }) => {
  return (
    
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'black',
        color: 'white',
        padding: '40px 0',
        mt: 'auto', // This pushes the footer to the bottom
        scrollSnapAlign: currentPage === 'Home' ? 'start' : 'none',
      }}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {/* Left Section: Logo and Navigation */}
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              {/* Clickable Logo to Home */}
              <Button onClick={() => handlePageChange('Home')} sx={{ p: 0 }}>
                <Box
                  component="img"
                  src={logo}
                  alt="AciSpace Logo"
                  sx={{ width: 225, height: 'auto', marginBottom: 2 }}
                />
              </Button>

              {/* Navigation Links */}
              <Box display="flex" gap={3} mb={2}>
                <Link
                  onClick={() => handlePageChange('About Us')}
                  color="inherit"
                  underline="hover"
                  sx={{ cursor: 'pointer' }}>
                  About Us
                </Link>
                <Link
                  onClick={() => handlePageChange('Solutions')}
                  color="inherit"
                  underline="hover"
                  sx={{ cursor: 'pointer' }}>
                  Solutions
                </Link>
                {/*<Link
                  onClick={() => handlePageChange('Contact')}
                  color="inherit"
                  underline="hover"
                  sx={{ cursor: 'pointer' }}>
                  Contact
                </Link>*/}
                {/*<Link
                  onClick={() => handlePageChange('Contact')}
                  color="inherit"
                  underline="hover"
                  sx={{ cursor: 'pointer' }}>
                  Customer Care
                </Link>*/}
              </Box>
            </Box>
          </Grid>

          {/* Right Section: Contact and Newsletter */}
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2}>
              {/* Contact Info */}
              <Typography variant="h6" gutterBottom>
                Contact us
              </Typography>
              <Box display="flex" alignItems="center" gap={1}>
                <LinkedIn sx={{ fontSize: 20 }} />
                <Link 
                  href="https://www.linkedin.com/company/acispace/" 
                  color="inherit" 
                  underline="hover" 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  linkedin.com/acispace
                </Link>
              </Box>
              <Box display="flex" alignItems="center" gap={1}>
                <Email sx={{ fontSize: 20 }} />
                <Link href="mailto:info@acis.space" color="inherit" underline="hover">
                  info@acis.space
                </Link>
              </Box>

              {/* Newsletter Subscription */}
              {/*<Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
                Get in touch
              </Typography>
              <Box display="flex" gap={1} alignItems="center" width="100%">
                <TextField
                  variant="outlined"
                  placeholder="Your email address..."
                  fullWidth
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    input: { color: 'black' },
                  }}/>
                <Button variant="contained" color="info" sx={{ height: '100%' }}>
                  Subscribe
                </Button>
              </Box>*/}
            </Box>
          </Grid>
        </Grid>

        {/* Policies and Bottom Copyright Notice */}
        <Box textAlign="center" mt={4} borderTop="1px solid gray" pt={2}>
          <Grid container spacing={3}>
            {/*<Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Box display="flex" gap={3} mb={2}>
                  <Link href="/terms" color="inherit" underline="hover">
                    Terms & Conditions
                  </Link>
                  <Link href="/privacy" color="inherit" underline="hover">
                    Privacy Policy
                  </Link>
                  <Link href="/accessibility" color="inherit" underline="hover">
                    Accessibility
                  </Link>
                  <Link href="/legal" color="inherit" underline="hover">
                    Legal
                  </Link>
                </Box>
              </Box>
            </Grid>*/}

            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2}>
                <Typography variant="body2" color="gray">
                  AciSpace © {new Date().getFullYear()} All rights reserved.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
