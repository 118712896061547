// --- Introductions ---
export const hanga_intro = `
Hanga is a seasoned space and software engineer with extensive experience across satellite missions, including Galileo Second Generation (G2G), OPS-SAT, PULSE, and the Copernicus Sentinels. In G2G, she developed an innovative automation component that orchestrates on-ground tasks between system components, enhancing operational efficiency. During her time on OPS-SAT, she worked on advanced image processing, both with and without AI, and some of her work was submitted to ESA's Kepler competition.
In 2024, she showcased her forward-thinking approach to space exploration by designing a space mining station for the International Space Challenge in Singapore. Hanga is also a researcher in neuromorphic computing for space debris mitigation, committed to addressing critical challenges in space sustainability.
`;

export const joseph_intro = `
Tzu-Chiao Lin is a multidisciplinary space systems engineer with expertise spanning mechanical, electrical, and software engineering. He has extensive experience in satellite missions, including IRIS-A, IRIS-B, IRIS-F1, the MIST CubeSat mission, and the FORMOSAT-8A secondary payload. His innovative work on satellite systems and hardware optimization earned him the prestigious Japan Society for Aeronautical and Space Science Prize. Tzu-Chiao has authored 11 publications in space technologies.
He also brings valuable expertise as a remote-sensing engineer and wireless communication specialist, with hands-on experience using Sentinel-1 SAR radar imagery for Earth observation. Dedicated to advancing space technologies, Tzu-Chiao focuses on optimizing space system design and planning cutting-edge missions such as Earth Observation (EO) and Optical Inter-Satellite Link (OISL) missions.
`;

export const thomas_intro = `
With both solid backgrounds in Aerospace and R&D, Thomas is a dedicated Aerospace Engineer with abundant knowledge in AIT and space mission simulations. He has successfully tested, troubleshot and resolved critical subsystem-level anomalies on developing CubeSats via ground functional/integrated and system-level test campaigns. Currently conducting research with specialization in Spacecraft Formation Flying, he is working alongside a space robotics laboratory to implement mission scenarios via high fidelity frameworks and Hardware-In-the-Loop simulations, these to achieve automatic space rendezvous, docking and debris tracking/removal via various controller applications.
`;

export const boldi_intro = `
Boldizsár Markotics is an electrical and space engineer specializing in system design, component validation, and the development of advanced instrumentation for aerospace applications. His experience spans CubeSat and UAV ecosystem development, as well as instrument and experiment design for space missions. Recently, Boldizsár played a key role in the system design and prototype manufacturing for a sounding rocket project. With expertise in radar systems, micro-Doppler analysis, satellite attitude control, and data visualization for nanosatellites, he focuses on optimizing mission-critical infrastructure and ensuring efficient, practical solutions for space exploration challenges.
Boldizsár is committed to driving innovation in the space sector, with a focus on making space exploration more accessible and efficient through cutting-edge engineering solutions.
`;

export const maroc_intro = `
Marcell is a skilled cyber security engineer with a strong background in the telecommunications sector. He was a key member of the award-winning team at the MVM Cybersecurity Hackathon in Hungary, where his innovative approach to secure software development significantly contributed to the team's success. Marcell's multidisciplinary expertise encompasses both the business and technical aspects of projects, providing valuable insights into hardware and software integration. His commitment to enhancing organizational security through advanced engineering practices positions him as a vital asset in the field of cyber security.
`;

export const eric_intro = `
Eric is a project manager with a background in systems engineering, having successfully managed multiple companies and non-profit organizations.​ He has also gained experience in the satellite field, participating in CubeSat missions such as IRIS-A, BS-1, BS-1A, and BS-2. He won first place in the CubeSat Mission Design Contest as a systems engineer on a team of only three members, serving as a communication bridge between scientists and engineers. In addition, Eric has published several papers and received the Excellence Engineer Medal from the Houston Space Center in the United States. He excels at communicating with individuals from different fields, creating opportunities for collaborative growth. Recently, he assisted U.S. space system managers in verifying the feasibility analysis of their large satellite orbits.
`;

export const en_intro = `
En is an experienced embedded system engineer with particular strength in FPGA and RF for space applications. He has been instrumental in designing command & control, power distribution, and RF communication systems for rockets and CubeSats in Taiwan. En excels at integrating software and hardware components, ensuring seamless communication and functionality across complex space systems. As a dedicated engineer, En continues to push the boundaries of space technology, focusing on enhancing the reliability and efficiency of space missions.
`;

// --- Project card descriptions ---

export const board_card = `
A versatile, plug-and-play solution offering high performance and flexibility, tailored for space applications like radio, imaging, and complex experiments.
`;

export const board_desc = `

`;

export const acisw_card = `
Easily design, simulate, and optimize CubeSat payloads with AciSoftware's intuitive interface and automated code generation.
`;

export const acisw_desc = `
Developing robust code for space payloads is a complex process, often involving intricate driver setups, debugging, and maintenance. This challenge is compounded for universities and smaller teams with limited experience in the field. Current solutions are difficult to use, lack clear documentation, and don't offer user-friendly simulation tools.
AciSoftware changes that. Our innovative solution provides an easy-to-use graphical interface (GUI) for building payload software with predefined components, customizable code blocks, and automatic code generation. Users can simulate their designs, identify issues early, and refine their code before hardware deployment.
AciSoftware ensures a seamless development process with comprehensive documentation and a step-by-step guide, helping teams of all sizes create high-quality space mission payloads efficiently.
`;

// --- Team ---

export const who_we_are= `
We are a pioneering space technology startup driven by a passion for breaking down the barriers to space exploration. Founded by a team of innovators with deep expertise in the space industry, our mission is to transform the way CubeSat payloads are integrated and deployed. We believe that space should be accessible to all—whether you're a researcher, an entrepreneur, or an organization aiming to explore new frontiers. By embracing values like innovation, collaboration, and customer-centricity, we are making space missions faster, more affordable, and more efficient than ever before.
`;

export const what_we_do = `
At the core of our work, we offer streamlined, compact solutions for CubeSat payload integration, addressing the industry's growing demand for fast and reliable space missions. We specialize in reducing the time and complexity of payload development, ensuring that innovators and researchers can focus on their goals without being bogged down by technical hurdles. By providing cutting-edge technology that balances performance with affordability, we enable our clients to accelerate mission readiness and achieve success with fewer resources.
Our goal is simple: to empower you to explore space with minimal barriers, while we handle the complexities of integration and deployment.
`;

export const our_vision = `
Our vision is to revolutionize space missions by streamlining CubeSat payload integration, making it faster, more accessible, and affordable for everyone. We aim to break down traditional barriers that have limited the potential for innovation, empowering a new generation of innovators, researchers, and organizations to explore the final frontier. By providing cutting-edge solutions and fostering collaboration across industries, we strive to democratize space exploration, enabling even small teams with big ideas to make a significant impact on future space missions. Our ultimate goal is to unlock new possibilities in space technology, exploration, and scientific discovery for the betterment of all.
`;

export const value_innovation = `
We are committed to pushing the boundaries of space technology by offering cutting-edge, compact solutions that meet the evolving needs of the industry.
`;

export const value_efficiency = `
We focus on reducing development time, offering streamlined processes, and delivering practical solutions that accelerate mission readiness.
`;

export const value_affordability =`
We believe that high-quality space technology should be accessible to all. We are dedicated to offering a good value-cost ratio without compromising on quality.
`;